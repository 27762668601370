import classNames from "classnames";
import useTranslation from "next-translate/useTranslation";
import React from "react";

import IconInfo from "assets/images/icons/helpInfo.svg";
import Button from "components/common/Button";
import Info from "components/common/Info";
import { ApiHelpInfosGet } from "services/api/helpInfos";
import { useAppDispatch } from "stores";
import { uiShowDocsKeyUpdated, uiUpdated } from "stores/features/ui";
import { SECTION } from "utils/constants";
import { useHelpInfo } from "utils/hooks";
import { Paths } from "utils/objects";

import styles from "./styles.module.scss";

interface Props {
  children?: React.ReactNode;
  className?: string;
  configKey?: Paths<ApiHelpInfosGet>;
  contentClassName?: string;
  headerClassName?: string;
  headerTitleClassName?: string;
  headerButtons?: React.ReactNode;
  headerExtra?: React.ReactNode;
  hideHeader?: boolean;
  noBorder?: boolean;
  section?: SECTION | string;
}

const Section: React.FC<Props> = ({
  children,
  className,
  configKey,
  contentClassName,
  headerClassName,
  headerTitleClassName,
  headerButtons,
  headerExtra,
  hideHeader,
  section,
}) => {
  const dispatch = useAppDispatch();
  const [helpInfoMessage, helpInfoLink] = useHelpInfo(configKey);
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        styles.section,
        hideHeader && styles.hideHeader,
        className
      )}
    >
      {!hideHeader && (
        <header
          className={classNames(
            styles.header,
            helpInfoLink && styles.paddingBottom,
            (section === SECTION.nativeNavigation ||
              section === SECTION.services) &&
              styles.withTabAndSelect,
            section === SECTION.pushNotifications && styles.withTab,
            headerClassName
          )}
        >
          <div className={styles.headerContent}>
            <h2
              className={classNames(styles.headerTitle, headerTitleClassName)}
            >
              {t(`sections.${section}.title`)}
              {helpInfoLink && (
                <Button
                  className={styles.infoLink}
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    dispatch(uiUpdated({ showDocs: helpInfoLink }));
                    dispatch(uiShowDocsKeyUpdated());
                  }}
                >
                  <IconInfo />
                  <span className={styles.infoLinkText}>
                    {t("common.infoLinkLabel")}
                  </span>
                </Button>
              )}
              {headerButtons && headerButtons}
            </h2>
            {helpInfoMessage && (
              <Info className={styles.infoMessage}>{helpInfoMessage}</Info>
            )}

            {headerExtra}
          </div>
        </header>
      )}

      <div className={contentClassName}>{children}</div>
    </div>
  );
};

export default Section;
