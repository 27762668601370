import classNames from "classnames";
import useTranslation from "next-translate/useTranslation";
import React from "react";

import IconInfo from "assets/images/icons/helpInfo.svg";
import IconAndroid from "assets/images/icons/IconAndroid";
import IconIos from "assets/images/icons/IconIos";
import IconTrash from "assets/images/icons/trash.svg";
import { useAppDispatch } from "stores";
import { uiShowDocsKeyUpdated, uiUpdated } from "stores/features/ui";
import urls from "utils/urls";

import Button from "../Button";
import Tooltip from "../Tooltip";
import styles from "./styles.module.scss";

interface Props {
  androidContent?: React.ReactNode;
  androidContentHeader?: React.ReactNode;
  fitContent?: boolean;
  children?: React.ReactNode;
  className?: string;
  filler?: boolean;
  full?: boolean;
  generalContent?: React.ReactNode;
  generalContentHeader?: React.ReactNode;
  iconContainerClassName?: string;
  inline?: boolean;
  iosContent?: React.ReactNode;
  iosContentB?: React.ReactNode;
  iosContentHeader?: React.ReactNode;
  iosContentHeaderB?: React.ReactNode;
  iosAndAndroidContent?: React.ReactNode;
  iosAndAndroidContentHeader?: React.ReactNode;
  onRemoveIosAndAndroid?: () => void;
  onRemoveIos?: () => void;
  onRemoveAndroid?: () => void;
  removableIosAndAndroid?: boolean;
  removableIos?: boolean;
  removableAndroid?: boolean;
  withoutMargin?: boolean;
  iosInfolink?: string;
  androidInfolink?: string;
}

const Subsection: React.FC<Props> = ({
  androidContent,
  androidContentHeader,
  fitContent,
  children,
  className,
  filler,
  full,
  generalContent,
  generalContentHeader,
  iconContainerClassName,
  inline,
  iosContent,
  iosContentB,
  iosContentHeader,
  iosContentHeaderB,
  iosAndAndroidContent,
  iosAndAndroidContentHeader,
  onRemoveIosAndAndroid,
  onRemoveIos,
  onRemoveAndroid,
  removableIosAndAndroid,
  removableIos,
  removableAndroid,
  iosInfolink,
  androidInfolink,
  withoutMargin,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  return (
    <div
      className={classNames(
        styles.subsection,
        { [styles.inline]: inline },
        { [styles.withoutMargin]: withoutMargin },
        className
      )}
    >
      {generalContent && (
        <div className={classNames(styles.iconLess)}>
          {generalContentHeader && (
            <div className={styles.subsectionContentHeader}>
              {generalContentHeader}
            </div>
          )}
          {generalContent}
        </div>
      )}
      {iosAndAndroidContent && (
        <div
          className={classNames(
            styles.subsectionContentContainer,
            fitContent && styles.fitContent
          )}
        >
          <div
            className={classNames(
              styles.subsectionHeader,
              iconContainerClassName
            )}
          >
            <div className={styles.iconContainer}>
              <span className={styles.icon}>
                <IconIos />
                <IconAndroid />
              </span>
              <span className={styles.iconLabel}>
                {t("common.ios")} and {t("common.android")}
              </span>
            </div>
            {removableIosAndAndroid && (
              <Tooltip placement="bottom" label={t("common.remove")}>
                <Button onClick={onRemoveIosAndAndroid} isIcon>
                  <IconTrash />
                </Button>
              </Tooltip>
            )}
          </div>
          <div
            className={classNames(
              styles.subsectionContent,
              full && styles.full
            )}
          >
            {iosAndAndroidContentHeader && (
              <div className={styles.subsectionContentHeader}>
                {iosAndAndroidContentHeader}
              </div>
            )}
            {iosAndAndroidContent}
          </div>
        </div>
      )}
      {iosContent && (
        <div
          className={classNames(
            styles.subsectionContentContainer,
            fitContent && styles.fitContent
          )}
        >
          <div
            className={classNames(
              styles.subsectionHeader,
              iosInfolink && styles.withInfoLink,
              iconContainerClassName
            )}
          >
            <div className={styles.iconContainer}>
              <span className={styles.icon}>
                <IconIos />
              </span>
              <span className={styles.iconLabel}> {t("common.ios")}</span>
            </div>
            {iosInfolink && (
              <Button
                className={styles.infoLink}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  dispatch(
                    uiUpdated({
                      showDocs: `${urls.documentation(iosInfolink)}`,
                    })
                  );
                  dispatch(uiShowDocsKeyUpdated());
                }}
              >
                <IconInfo />
                <span className={styles.infoLinkText}>
                  {t("common.releaseNotes")}
                </span>
              </Button>
            )}

            {removableIos && (
              <Tooltip placement="bottom" label={t("common.remove")}>
                <Button onClick={onRemoveIos} isIcon>
                  <IconTrash />
                </Button>
              </Tooltip>
            )}
          </div>
          <div
            className={classNames(
              styles.subsectionContent,
              full && styles.full
            )}
          >
            {iosContentHeader && (
              <div className={styles.subsectionContentHeader}>
                {iosContentHeader}
              </div>
            )}
            {iosContent}
          </div>
        </div>
      )}
      {iosContentB && (
        <div
          className={classNames(
            styles.subsectionContentContainer,
            fitContent && styles.fitContent
          )}
        >
          <div
            className={classNames(
              styles.subsectionHeader,
              iconContainerClassName
            )}
          >
            <div className={styles.iconContainer}>
              <span className={styles.icon}>
                <IconIos />
              </span>
              <span className={styles.iconLabel}> {t("common.ios")}</span>
            </div>

            {removableIos && (
              <Tooltip placement="bottom" label={t("common.remove")}>
                <Button onClick={onRemoveIos} isIcon>
                  <IconTrash />
                </Button>
              </Tooltip>
            )}
          </div>
          <div
            className={classNames(
              styles.subsectionContent,
              full && styles.full
            )}
          >
            {iosContentHeaderB && (
              <div className={styles.subsectionContentHeader}>
                {iosContentHeaderB}
              </div>
            )}
            {iosContentB}
          </div>
        </div>
      )}
      {androidContent && (
        <div
          className={classNames(
            styles.subsectionContentContainer,
            fitContent && styles.fitContent
          )}
        >
          <div
            className={classNames(
              styles.subsectionHeader,
              androidInfolink && styles.withInfoLink,
              iconContainerClassName
            )}
          >
            <div className={styles.iconContainer}>
              <span className={styles.icon}>
                <IconAndroid />
              </span>
              <span className={styles.iconLabel}> {t("common.android")}</span>
            </div>

            {androidInfolink && (
              <Button
                className={styles.infoLink}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  dispatch(
                    uiUpdated({
                      showDocs: `${urls.documentation(androidInfolink)}`,
                    })
                  );
                  dispatch(uiShowDocsKeyUpdated());
                }}
              >
                <IconInfo />
                <span className={styles.infoLinkText}>
                  {t("common.releaseNotes")}
                </span>
              </Button>
            )}
            {removableAndroid && (
              <Tooltip placement="bottom" label={t("common.remove")}>
                <Button onClick={onRemoveAndroid} isIcon>
                  <IconTrash />
                </Button>
              </Tooltip>
            )}
          </div>
          <div
            className={classNames(
              styles.subsectionContent,
              full && styles.full
            )}
          >
            {androidContentHeader && (
              <div className={styles.subsectionContentHeader}>
                {androidContentHeader}
              </div>
            )}
            {androidContent}
          </div>
        </div>
      )}
      {children && (
        <div
          className={classNames(
            styles.subsectionContent,
            styles.full,
            styles.iconLess
          )}
        >
          {children}
        </div>
      )}
      {filler && <div className={styles.fillerContainer}>{}</div>}
    </div>
  );
};

export default Subsection;
